<!--
 * @Author: 王广婷
 * @Date: 2020-04-03 11:45:34
 * @LastEditors: 李波
 * @LastEditTime: 2020-07-23 17:48:03
 -->
<template>
  <div id="box">
    <div
      class="md-example-child-scroll-view"
      :style="{ height: `${height}px` }"
    >
      <md-scroll-view
        ref="scrollView"
        :scrolling-x="false"
        @end-reached="$_onEndReached"
        auto-reflow
      >
        <div class="top" v-if="bannerList.length > 0">
          <div class="banner">
            <!-- <img src="../../assets/xsqd/default.jpg" alt=""> -->
            <md-swiper
              :autoplay="3000"
              transition="fade"
              ref="swiperBtn"
              :is-prevent="false"
              v-if="bannerList.length > 0"
            >
              <md-swiper-item :key="index" v-for="(items, index) in bannerList">
                <img
                  :src="items.attachment.url"
                  v-if="items.attachment.path"
                  style="border-radius: 6px"
                  @click="linkTo(items, 'swiperBtn')"
                />
              </md-swiper-item>
            </md-swiper>
          </div>
        </div>
        <div class="mark1"></div>
        <div style="width: 100%; background: white">
          <header>
            <!-- <span :style="{color:getStorage('theme','')}">|</span> -->
            热销产品
          </header>
          <div class="slidBox" v-show="!noMes">
            <ul class="picBox">
              <li
                class="boxContent scroll-view-list"
                v-for="item in hotGoods"
                :key="item.id"
              >
                <div class="info">
                  <img
                    @click="linkTo(item, 'comtburl')"
                    v-if="item.iconhttp"
                    :src="ossurl + item.iconhttp"
                    alt="产品图"
                  />
                  <img v-else @click="linkTo(item, 'comtburl')" />
                  <i class="hotIcon" @click="linkTo(item, 'comtburl')"></i>
                  <div class="info-con">
                    <div class="title">
                      <div
                        :class="
                          item.baseid === 'HDJJ' || item.baseid === 'HDRS'
                            ? 'nameText'
                            : 'initName'
                        "
                        @click="linkTo(item, 'comtburl')"
                      >
                        {{ item.prodname }}
                      </div>
                      <div
                        class="shareText"
                        v-if="item.baseid === 'HDJJ' || item.baseid === 'HDRS'"
                        @click="shareUrl(item)"
                      >
                        <i>分享</i>
                      </div>
                    </div>
                    <!-- <div class="descript" >{{item.pdesc}}</div> -->
                    <div
                      @click="linkTo(item, 'comtburl')"
                      v-if="item.pdesc"
                      class="descript"
                    >
                      {{ item.pdesc }}
                    </div>
                    <div
                      v-else
                      class="descript"
                      @click="linkTo(item, 'comtburl')"
                      style="height: 0.5rem"
                    ></div>
                    <div class="price" @click="linkTo(item, 'comtburl')">
                      ￥
                      <span>{{ item.minprice ? item.minprice : 0 }}</span
                      >元/年起
                    </div>
                    <!-- <div class="title">测试数据重看涣发大号放假哈好几回规范化国家恢复到看后感闪电发货</div>
                                <div class="descript">线上免体检保额高达350万，健告宽松和免责仅3条，定期寿险转换权和终身寿险转换权户口地号放假ad是返回健康第三方</div>
                                <div class="price">
                                  ￥<span>{{item.minprice?item.minprice:0}}</span>元/年起
                    </div>-->
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="noMes" class="noMes">
          <img src="../../assets/xsqd/nomessage.png" alt />
        </div>
        <md-scroll-view-more
          style="padding-bottom: 1rem !important"
          slot="more"
          :is-finished="isFinished"
        ></md-scroll-view-more>
      </md-scroll-view>
    </div>
    <div class="footer">
      <ul>
        <router-link tag="li" to="/main">
          <i class="hotSall heightStyle"></i>
          <span style="color: rgba(255, 115, 38, 1)">热销</span>
        </router-link>
        <router-link tag="li" to="/Class">
          <i class="product heightStyle"></i>
          <span>产品库</span>
        </router-link>
        <router-link tag="li" to="/UserInfo">
          <i class="mine heightStyle"></i>
          <span>我的</span>
        </router-link>
      </ul>
    </div>
    <div class="share" @click="cancelShare" v-show="isShareCard">
      <img src="@/assets/abt/img/fxbg.png" />
    </div>
  </div>
</template>
<script>
import { homelist, getNewUrl, channelCoopCall } from "@/api/xsqd/index";
import { Dialog, Toast } from "mand-mobile";
import { getStorage, getUrlParameter } from "@/lib/util";
import axios from "axios";
// import { getInsureUrl } from '@/api/agent/agent/index'
import config from "@/config";
import { wechatshare } from "@/lib/wechat_share";
const { redirect_uri } = config;
export default {
  data() {
    return {
      page: 1,
      totalPageCount: 2,
      hotGoods: [],
      noMes: false,
      ossurl: "https://saasms-test.oss-cn-beijing.aliyuncs.com/",
      isFinished: false,
      height: 0,
      cliflag: true,
      bannerList: [],
      isShareCard: false,
    };
  },
  created() {
    this.ossurl = getStorage("ossurl", "");
    this.user = getStorage("u_s", {});
    this.getList();
    this.$nextTick(() => {
      setTimeout(() => {
        this.height =
          window.innerHeight -
          document.getElementsByClassName("footer")[0].clientHeight;
      }, 500);
    });
    wechatshare("", "", "", "", "", "", true);
    if (sessionStorage.getItem("productType")) {
      sessionStorage.removeItem("productType");
    }
  },
  methods: {
    //下拉加载
    $_onEndReached() {
      if (this.page >= this.totalPageCount) {
        this.isFinished = true;
      } else {
        this.page += 1;
        this.getList();
        this.$refs.scrollView.finishLoadMore();
      }
    },
    // 加载商品
    getList() {
      homelist({ page: this.page, size: 10, flag: "1", isHome: "1" }).then(
        (res) => {
          this.bannerList = res.data.data.bannerList;
          let rows = res.data.data.rows;
          let tatal = res.data.data.total;
          this.totalPageCount = Math.ceil(tatal / 10);
          if (rows.length != 0) {
            for (let i = 0; i < rows.length; i++) {
              this.hotGoods.push(rows[i]);
            }
          } else {
            this.noMes = true;
          }
        }
      );
    },
    //产品跳转
    linkTo(val, value) {
      let item;
      if (value === "swiperBtn") {
        item = this.bannerList[this.$refs.swiperBtn.getIndex()];
      } else {
        item = val;
      }
      if (!this.cliflag) {
        return;
      }
      this.cliflag = false;
      // if(item.baseid == 'HDJJ') {
      //   this.getHdUrl(item)
      //   return
      // }
      this.$store
        .dispatch("SALES_PERMISSION", {
          url: item.comtburl,
          baseid: item.baseid,
        })
        .then((response) => {
          if (response.urltype == "W" && item.baseid == "YSYF") {
            item.tburl = `${item.comtburl.split("?")[0]}?userTrack=${
              response.userTrack
            }`;
          } else if (response.urltype == "W" && item.baseid == "ZAZX") {
            item.tburl = `${item.comtburl.split("param=")[0]}&bizContent=${
              response.bizContent
            }`;
          } else if (item.baseid == "ZXJJ") {
            if (item.comtburl.includes("userTrack") > -1) {
              item.tburl = item.comtburl.replace("userTrack", response.agent);
            } else {
              item.tburl = `${item.comtburl}&agent=${response.agent}`;
            }
          } else if (response.urltype == "W" || item.baseid == "TPRS") {
            item.tburl = item.comtburl.includes("?")
              ? item.comtburl
              : `${item.comtburl}?comid=${item.comid}&prodcode=${response.prodcode}&empno=${response.empno}`;
          } else if (response.urltype == "W") {
            item.tburl = item.comtburl.includes("?")
              ? `${item.comtburl}&comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`
              : `${item.comtburl}? comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`;
          } else {
            item.tburl = item.comtburl;
          }
          if (item.jumpmode === "2") {
            let channelToken = getUrlParameter(item.comtburl, "channelToken");
            let channelProd = getUrlParameter(item.comtburl, "channelProd");

            let params = {
              bizType: 1,
              data: {
                channelProd: channelProd,
                channelToken: channelToken,
              },
              channel: item.baseid,
              operType: 1,
            };
            channelCoopCall(params)
              .then((res) => {
                Toast.hide();
                if (res.data.code === 200) {
                  if (res.data.data) {
                    window.location.href = res.data.data;
                  }
                }
              })
              .catch((err) => {
                Toast.hide();
              });
          } else {
            window.location.href = `${item.tburl}&source=qd`;
          }
        })
        .finally(() => {
          this.cliflag = true;
        });
      // window.location.href = `${item[value]}&source=qd`
    },
    // getHdUrl (item) {
    //   const obj = {
    //     baseid: item.baseid,
    //     url: item.comtburl
    //   }
    //   getInsureUrl(obj).then(response => {
    //     window.location.href = `${response.data.data}&source=qd`
    //   }).finally(() => {
    //     this.cliflag = true
    //   })
    // },
    // 轮播图点击事件
    banclick() {
      let ele = this.getbanner[this.$refs.swiper.getIndex()];
      if (ele.urltype === "O" || ele.urltype === "P") {
        window.location.href = ele.urlhttp;
      } else {
        this.$router.push({
          path: "/rmhddetail",
          query: { type: "banner", bannerhttp: ele.urlhttp },
        });
      }
    },
    //分享产品
    shareUrl(item) {
      this.isShareCard = true;
      // let fxstr={
      //    suserid: this.user.userid,
      //    empno:this.user.empno,
      //    rytype:this.user.rytype,
      //    otype: '1',
      //    btagcode: '7'
      // }
      wechatshare(
        item.prodname + "产品分享",
        item.comtburl,
        this.ossurl + item.iconhttp,
        encodeURI(
          redirect_uri + "/shareLinkPage?shareParamData=" + item.comtburl
        ),
        "",
        "no"
      );
    },
    cancelShare() {
      this.isShareCard = false;
    },
  },
};
</script>
<style lang='stylus' scoped>
#box {
  margin: 0px;
  padding: 0px;
  font-family: '微软雅黑';
  font-size: 14px;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: white;
}

.top {
  width: 100%;
  height: 3.9rem;
  background: white;

  .banner {
    // height: 94%;
    height: 3.73rem;
    width: 94%;
    margin: auto;
    margin-top: 0.27rem;
    // margin:0.27rem 0.32rem;
  }
}

.mark1 {
  width: 100%;
  height: 15px;
  background: #f1f1f1;
}

header {
  font-size: 0.48rem;
  font-weight: bold;
  text-align: left;
  padding-left: 0.5rem;
  line-height: 1rem;
  color: rgba(51, 51, 51, 1);

  span {
    color: #ff0300;
    font-weight: bolder;
  }
}

.md-example-child-scroll-view {
  height: 15rem;
}

.info {
  width: 100%;
  display: flex;
  height: 2.5rem;

  img {
    width: 2.135rem;
    height: 2.135rem;
    margin: 0 0.5rem;
    background: rgba(248, 228, 185, 1);
    position: relative;
    border-radius: 0.16rem;
  }

  .info-con {
    display: flex;
    width: 65%;
    flex-direction: column;
    // justify-content: space-between;
    text-align: left;

    .title {
      display: flex;

      .initName {
        font-size: 0.36rem;
        font-weight: bold;
        color: rgba(55, 55, 55, 1);
        text-overflow: ellipsis;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 块元素显示的文本行数
        -webkit-box-orient: vertical;
      }

      .nameText {
        flex: 0 0 75%;
      }

      .shareText {
        flex: 0 0 25%;
        text-align: right;

        i {
          display: inline-block;
          border: 1px solid #ffd9b9;
          border-radius: 0.25rem;
          padding: 0.05rem 0.2rem;
          background-image: linear-gradient(to bottom, #fff5ec, #ffdfd1);
          color: rgb(236, 45, 56);
          font-weight: 400;
          font-size: 0.33rem;
        }
      }
    }

    .descript {
      color: rgba(120, 120, 120, 1);
      font-size: 0.29rem;
      max-width: 6.4rem;
      overflow: hidden;
      padding: 1px 0 4px 0;
      text-overflow: ellipsis;
      // white-space: nowrap;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 块元素显示的文本行数
      -webkit-box-orient: vertical;
    }

    .price {
      color: rgba(255, 115, 38, 1);
      font-size: 0.3rem;
      font-weight: bold;
      margin-top: 5px;

      span {
        font-size: 0.503rem;
      }
    }
  }
}

.hotIcon {
  position: absolute;
  width: 0.64rem;
  height: 0.35rem;
  left: 1.995rem;
  background-image: url('~@/assets/image/home_hot.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.boxContent {
  width: 100%;
  padding: 0.3rem 0;
  background: #ffffff;
  margin-right: 0.5rem;
  // border-bottom:1px solid #e4e2e2;
  display: flex;
  justify-content: space-between;
}

.picBox {
  width: 100%;
  background: #ffffff;
  padding-right: 0;
  padding-bottom: 0;
}

.slidBox {
  background: white;
}

.footer {
  background-color: #fff;
  width: 100%;
  height: 1.4rem;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  z-index: 9;
}

.footer>ul {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer ul li {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footer ul li span {
  font-size: 0.35rem;
  color: #6b6b6b;
}

.footer ul li i {
  font-size: 1rem;
  color: #6b6b6b;
}

.noMes {
  width: 100%;
  height: auto !important;
  text-align: center;
}

.noMes img {
  width: 5rem;
}

.heightStyle {
  width: 0.72rem;
  height: 0.72rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hotSall {
  background-image: url('~@/assets/image/home_selected.png');
}

.product {
  background-image: url('~@/assets/image/product_unselected.png');
}

.mine {
  background-image: url('~@/assets/image/my_unselected.png');
}

.share {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  z-index: 999;
  position: fixed;
  top: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
  }
}
</style>
